@use '../../components/colors';

.btn {
    font-size: 1.125rem;
    height: 40px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 0;
}

.btn:focus {
    box-shadow: none;
}

.btn-primary {
    color: colors.$white;
    background-color: colors.$activeBlue;
    border-color: colors.$activeBlue;

    .btn-primary:disabled {
        color: colors.$white;
        background-color: colors.$grey3;
        border-color: colors.$grey3;
    }

    &:not(.disabled) {
        &:hover,
        &:focus,
        &:active {
            color: colors.$white;
            background-color: colors.$brandBlue;
            border-color: colors.$brandBlue;
        }
    }
}

.btn-outline-primary {
    color: colors.$activeBlue;
    background-color: colors.$white;
    border-color: colors.$activeBlue;

    &:not(.disabled) {
        &:hover,
        &:active,
        &:focus {
            color: colors.$white;
            background-color: colors.$brandBlue;
            border-color: colors.$brandBlue;
        }
    }
}
