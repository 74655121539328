@use '../../components/colors';

.tab-content .tab-pane .container {
    border: 1px solid colors.$grey3;
}

.nav-tabs {
    .nav-link {
        border-radius: 0;
        border-bottom: 3px solid transparent;
        margin-right: -1px;
        margin-bottom: -1px;
        font-weight: 600;
        color: colors.$activeBlue;
        padding-left: 2rem;
        padding-right: 2rem;

        white-space: nowrap;
    }

    .nav-link.active {
        font-weight: bold;
        // Decrease letter spacing so that bold text takes up roughly the same width as normal text
        letter-spacing: -0.02rem;
        color: colors.$brandBlue;
        border-bottom-color: colors.$brandBlue;
    }

    .nav-link:focus,
    .nav-link:hover,
    .nav-link:active {
        background-color: rgba(colors.$activeBlue, 0.08);
        border-bottom-color: colors.$activeBlue;

        &.active {
            cursor: default;
            border-bottom-color: colors.$brandBlue;
        }
    }
}
