@use 'sass:string';
@use './colors';

// Based on:
// https://css-tricks.com/creating-a-maintainable-icon-system-with-sass/
@function str-replace($string, $search, $replace: '') {
    $index: string.index($string, $search);
    @if $index != null {
        @return string.slice($string, 0, $index) + $replace +
            str-replace(string.slice($string, $index + string.length($search)), $search, $replace);
    }
    @return $string;
}

@function swap-color($icon, $originalColor: #ffffff, $newColor: #ffffff) {
    $originalColor: str-replace('#{$originalColor}', '#', '%23');
    $newColor: str-replace('#{$newColor}', '#', '%23');
    @return str-replace($icon, $originalColor, $newColor);
}

$iconClose: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.0178 10.0166M10.0178 10.0166L18.9668 18.9644M10.0178 10.0166L19 1.03558M10.0178 10.0166L1.03318 19' stroke='%230072CE' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
$iconCloseHover: swap-color($iconClose, colors.$activeBlue, colors.$brandBlue);

$logo: url("data:image/svg+xml,%3Csvg class='nav_home_img' width='94' height='32' viewBox='0 0 94 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M62.3844 7.78728H53.4123V32H41.7655V7.78728H32.7755V0.0179513H62.3844V7.78728ZM93.2247 7.66162V0H66.8219V24.3133C66.8219 28.5103 70.2255 31.9102 74.4189 31.9102H79.3627H93.3611V24.2127H79.8079C78.8278 24.2127 78.0307 23.4157 78.0307 22.4355V19.2761H92.6144V12.1853H78.0307V7.66162H93.2247ZM19.7177 31.9749H0V0.0502637H19.6531C24.8482 0.0502637 29.056 4.26164 29.056 9.45316V22.6366C29.056 27.7922 24.8769 31.9749 19.7177 31.9749ZM18.2888 21.8826V10.0061C18.2888 8.65253 17.1902 7.5575 15.8402 7.5575H11.5283V24.3312H15.8402C17.1902 24.3312 18.2888 23.2362 18.2888 21.8826Z' fill='white'%3E%3C/path%3E%3C/svg%3E");
$iconCancel: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.0278 1.75H1V0.25H11.0278C13.6358 0.25 15.75 2.36421 15.75 4.97222C15.75 7.58023 13.6358 9.69444 11.0278 9.69444H2.81066L5.58589 12.4697L4.52523 13.5303L0.46967 9.47477C0.176777 9.18188 0.176777 8.70701 0.46967 8.41411L4.52523 4.35856L5.58589 5.41922L2.81066 8.19444H11.0278C12.8074 8.19444 14.25 6.75181 14.25 4.97222C14.25 3.19264 12.8074 1.75 11.0278 1.75Z' fill='white'/%3E%3C/svg%3E%0A");
$iconBack: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.29289 1.29289C4.68342 0.902369 5.31658 0.902369 5.70711 1.29289L11.7071 7.29289C12.0976 7.68342 12.0976 8.31658 11.7071 8.70711L5.70711 14.7071C5.31658 15.0976 4.68342 15.0976 4.29289 14.7071C3.90237 14.3166 3.90237 13.6834 4.29289 13.2929L9.58579 8L4.29289 2.70711C3.90237 2.31658 3.90237 1.68342 4.29289 1.29289Z' fill='white'/%3E%3C/svg%3E%0A");
$iconBackArrow: url("data:image/svg+xml,%0A%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.03125 2.625C9.72975 2.625 12.25 2.625 12.25 2.625M12.25 2.625V9.84375M12.25 2.625L2.625 12.25' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");

$calendarBase: url("data:image/svg+xml,%3Csvg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 24.6131H64M8 24.6131V63H64V24.6131M8 24.6131V12.8018H64V24.6131M24.1 9V16.6036M47.9 16.6036V9M19.2 33.4716H24.8V39.3773H19.2V33.4716ZM19.2 48.236H24.8V54.1416H19.2V48.236ZM33.2 33.4716H38.8V39.3773H33.2V33.4716ZM33.2 48.236H38.8V54.1416H33.2V48.236ZM47.2 33.4716H52.8V39.3773H47.2V33.4716ZM47.2 48.236H52.8V54.1416H47.2V48.236Z' stroke='%232B2B2B' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
$calendar: swap-color($calendarBase, colors.$black, colors.$activeBlue);
$calendarHighlight: swap-color($calendarBase, colors.$black, colors.$brandBlue);

$contract: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8H15' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
$contractHover: swap-color($contract, colors.$white, colors.$brandBlue);

$expand: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 1V8M8 15V8M8 8H1M8 8H15' stroke='%230072CE' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
$expandHover: swap-color($expand, colors.$activeBlue, colors.$brandBlue);

$linkArrowRight: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.29289 1.29289C4.68342 0.902369 5.31658 0.902369 5.70711 1.29289L11.7071 7.29289C12.0976 7.68342 12.0976 8.31658 11.7071 8.70711L5.70711 14.7071C5.31658 15.0976 4.68342 15.0976 4.29289 14.7071C3.90237 14.3166 3.90237 13.6834 4.29289 13.2929L9.58579 8L4.29289 2.70711C3.90237 2.31658 3.90237 1.68342 4.29289 1.29289Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
$linkArrowDown: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath transform='rotate(90, 8, 8)' fill-rule='evenodd' clip-rule='evenodd' d='M4.29289 1.29289C4.68342 0.902369 5.31658 0.902369 5.70711 1.29289L11.7071 7.29289C12.0976 7.68342 12.0976 8.31658 11.7071 8.70711L5.70711 14.7071C5.31658 15.0976 4.68342 15.0976 4.29289 14.7071C3.90237 14.3166 3.90237 13.6834 4.29289 13.2929L9.58579 8L4.29289 2.70711C3.90237 2.31658 3.90237 1.68342 4.29289 1.29289Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
$linkArrowLeft: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath transform='rotate(180, 8, 8)' fill-rule='evenodd' clip-rule='evenodd' d='M4.29289 1.29289C4.68342 0.902369 5.31658 0.902369 5.70711 1.29289L11.7071 7.29289C12.0976 7.68342 12.0976 8.31658 11.7071 8.70711L5.70711 14.7071C5.31658 15.0976 4.68342 15.0976 4.29289 14.7071C3.90237 14.3166 3.90237 13.6834 4.29289 13.2929L9.58579 8L4.29289 2.70711C3.90237 2.31658 3.90237 1.68342 4.29289 1.29289Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
$linkArrowUp: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath transform='rotate(270, 8, 8)' fill-rule='evenodd' clip-rule='evenodd' d='M4.29289 1.29289C4.68342 0.902369 5.31658 0.902369 5.70711 1.29289L11.7071 7.29289C12.0976 7.68342 12.0976 8.31658 11.7071 8.70711L5.70711 14.7071C5.31658 15.0976 4.68342 15.0976 4.29289 14.7071C3.90237 14.3166 3.90237 13.6834 4.29289 13.2929L9.58579 8L4.29289 2.70711C3.90237 2.31658 3.90237 1.68342 4.29289 1.29289Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");

$makeSelection: url("data:image/svg+xml,%3Csvg width='12' height='24' viewBox='0 0 12 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3831 15.736C10.6588 16.0117 10.6588 16.4586 10.3831 16.7343L6.14776 20.9696C5.87209 21.2453 5.42514 21.2453 5.14948 20.9696L0.914141 16.7343C0.638474 16.4586 0.638474 16.0117 0.914141 15.736C1.18981 15.4603 1.63675 15.4603 1.91242 15.736L5.64862 19.4722L9.38482 15.736C9.66048 15.4603 10.1074 15.4603 10.3831 15.736Z' fill='%230072CE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.911828 8.26383C0.636161 7.98816 0.636161 7.54122 0.911828 7.26555L5.14717 3.03022C5.42283 2.75455 5.86978 2.75455 6.14544 3.03022L10.3808 7.26555C10.6564 7.54122 10.6564 7.98816 10.3808 8.26383C10.1051 8.5395 9.65817 8.5395 9.3825 8.26383L5.6463 4.52763L1.91011 8.26383C1.63444 8.5395 1.1875 8.5395 0.911828 8.26383Z' fill='%230072CE'/%3E%3C/svg%3E%0A");
$makeSelectionHover: swap-color($makeSelection, colors.$activeBlue, colors.$brandBlue);

$check: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.375 5.18359L7.96154 15.1836L3.25 10.9975' stroke='%231E3575' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
